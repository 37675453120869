import React from "react";
import PropTypes from "prop-types";

import FilmPlayerProgressBar from "./filmPlayerProgressBar";
import FilmPlayerTimeElapsed from "./filmPlayerTimeElapsed";

import './film-player-controls.scss';

class FilmPlayerControls extends React.Component {
    constructor(props) {
        super(props);

        this._togglePlay = this._togglePlay.bind(this);
        this._initVideoEventHandlers = this._initVideoEventHandlers.bind(this);

        this.state = {
            timeElapsed: 0,
            buttonCopy: "play",
        }
    }

    componentDidMount() {
        this._initVideoEventHandlers();
    }

    _initVideoEventHandlers() {
        const video = this.props.videoRef.current;

        video.onpause = () => {
            this.setState({
                buttonCopy: "play",
            });
        }
        video.onplay = () => {
            this.setState({
                buttonCopy: "pause",
            });
        }
    }

    _togglePlay() {
        const video = this.props.videoRef.current;

        video.paused
            ? video.play()
            : video.pause();
    }

    render() {
        return (
            <div className={"film-player-controls"}>
                <button className={"film-player-controls__play font-primary"} onClick={this._togglePlay}>{this.state.buttonCopy}</button>
                <FilmPlayerTimeElapsed videoRef={this.props.videoRef} />
                <FilmPlayerProgressBar videoRef={this.props.videoRef} />
            </div>
        );
    }
}

FilmPlayerControls.propTypes = {
    videoRef: PropTypes.object.isRequired,
}

export default FilmPlayerControls;

import React from "react";
import { Link } from "gatsby";
import { graphql } from 'gatsby'

import SEO from "../../components/seo/seo";
import FilmPlayerControls from "../../components/filmPlayerControls/filmPlayerControls";
import ViewportContext from "../../components/utils/viewportContext";
import { getFilmFullVideoHref } from "../../components/utils/getters";

import './film-detail.scss';
import { throttle } from "throttle-debounce";

const AUTOPLAY_VIDEO = true;
const HIDE_NAV_CLASS = "hide-film-nav";

class FilmDetailPage extends React.Component {
    static contextType = ViewportContext;

    constructor(props) {
        super(props);

        this.videoRef = React.createRef();

        this._toggleDisplayAbout = this._toggleDisplayAbout.bind(this);
        this._handleMouseMove = this._handleMouseMove.bind(this);
        this._renderFilm = this._renderFilm.bind(this);

        const films = props.data.allFilm.edges.map(filmNode => filmNode.node);
        const film = films.find(film => film.slug === props.pageContext.filmSlug);

        this.state = {
            film: film,
            displayAbout: false,
            displayNav: true,
        };
    }

    componentDidMount() {
        this._mouseMoveThrottle = throttle(500, false, this._handleMouseMove);
        this._handleMouseMove();
    }

    componentWillUnmount() {
        clearTimeout(this.mouseMoveTimeout);
    }

    _handleMouseMove(e) {
        if (this.state.displayAbout) return;

        if (!this.state.displayNav) {
            this.setState({
                displayNav: true,
            });
        }

        clearTimeout(this.mouseMoveTimeout);
        this.mouseMoveTimeout = setTimeout( () => {
            this.setState({
                displayNav: false,
            });
        }, 3000);
    }

    _toggleDisplayAbout() {
        this.setState({
            displayAbout: !this.state.displayAbout,
            displayNav: false,
        });
    }

    _renderFilm() {
        const filmVideo = getFilmFullVideoHref(this.state.film);
        const hideNavClass = this.state.displayNav ? "" : HIDE_NAV_CLASS;
        const displayNativeControls = this.context !== 'largeScreen';

        return (
            <div className={"film-player"}>
                <video className={"film-player__video"} src={filmVideo} ref={this.videoRef}
                       loop playsInline={true} disableremoteplayback="true"
                       controls={displayNativeControls} autoPlay={AUTOPLAY_VIDEO}
                />

                {!displayNativeControls && (
                    <div className={`film-player__controls ${hideNavClass}`}>
                        <FilmPlayerControls videoRef={this.videoRef} />
                    </div>
                )}
            </div>
        )
    }

    _renderAbout() {
        const displayAboutClass = this.state.displayAbout ? "film-about--display" : ""
        return (
            <div className={`film-about ${displayAboutClass}`} onClick={this._toggleDisplayAbout}>
                <div className={"film-list-item"}>
                    <h3 className={"film-list-item__title font-secondary"}>{this.state.film.title}</h3>
                    <span className={"film-list-item__length"}>{this.state.film.film_length}</span>
                </div>
            </div>
        )
    }

    render() {
        const hideNavClass = this.state.displayNav ? "" : HIDE_NAV_CLASS;

        return (
            <div onMouseMove={this._mouseMoveThrottle}>
                <SEO
                    title={`${this.state.film.title} - TOMMY GENES`}
                />

                <div className={`film-nav ${hideNavClass}`}>
                    <button className={"font-primary film-nav__about"} onClick={this._toggleDisplayAbout}>About</button>
                    <Link className={"font-primary film-nav__close"} to={"/"}>Close</Link>
                </div>

                <div className={`film-footer ${hideNavClass}`}>
                    <button className={"font-primary film-footer__about"} onClick={this._toggleDisplayAbout}>About</button>
                </div>


                {this._renderAbout()}

                {this._renderFilm()}
            </div>
        );
    }
}

export default FilmDetailPage;

export const query = graphql`
query FilmQuery {
  allFilm {
    edges{
      node {
        slug
        title
        film_length
        video {
          url
        }
        video_short{
          url
        }
      }
    }
  }
}
 `;

const MEDIA_API_HOST = process.env.GATSBY_API_URL;

export const getFilmShortVideoHref = (film) => {
    return MEDIA_API_HOST + film.video_short.url;
}

export const getFilmFullVideoHref = (film) => {
    return MEDIA_API_HOST + film.video.url;
}

export const getFilmImageGif = (film) => {
    try {
        return MEDIA_API_HOST + film.image.url;
    } catch (e) {
        return null;
    }
}


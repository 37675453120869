import React from "react";
import PropTypes from "prop-types";

function calculateProgressPercentage(timeElapsed, duration) {
    return (timeElapsed / duration) * 100;
}

class FilmPlayerProgressBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeElapsed: 0,
        }
    }

    componentDidMount() {
        const video = this.props.videoRef.current;

        video.addEventListener("timeupdate", () => {
            this.setState({
                timeElapsed: video.currentTime,
            });
        });
    }

    render() {
        const video = this.props.videoRef.current;

        const progressBarWidth = (video && calculateProgressPercentage(this.state.timeElapsed, video.duration)) || 0;
        return (
            <div className={"film-player-controls__progress-bar"}>
                <div className={"film-player-controls__progress-bar__current"} style={{ width: `${progressBarWidth}%` }} />
            </div>
        );
    }
}

FilmPlayerProgressBar.propTypes = {
    videoRef: PropTypes.object.isRequired,
}

export default FilmPlayerProgressBar;

import React from "react";
import PropTypes from "prop-types";

function formatTimeElapsed(elapsedS) {
    const minutes = Math.floor(elapsedS / 60);
    const formattedMinutes = (minutes < 10 ? `0` : '') + minutes;

    const seconds = Math.floor(elapsedS % 60);
    const formattedSeconds = (seconds < 10 ? '0' : '') + seconds;

    return formattedMinutes + ":" + formattedSeconds;
}

class FilmPlayerTimeElapsed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeElapsedSeconds: 0,
        }
    }

    componentDidMount() {
        const video = this.props.videoRef.current;

        video.addEventListener("timeupdate", () => {
            this.setState({
                timeElapsedSeconds: Math.floor(video.currentTime),
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.timeElapsedSeconds !== nextState.timeElapsedSeconds;
    }

    render() {
        return (
            <span className={"film-player-controls__time font-primary"}>{formatTimeElapsed(this.state.timeElapsedSeconds)}</span>
        );
    }
}

FilmPlayerTimeElapsed.propTypes = {
    videoRef: PropTypes.object.isRequired,
}

export default FilmPlayerTimeElapsed;
